<template>
    <div class="sub_container">
      <div class="sub_wrapper">
        <h2 class="sub-title1">{{ $t('menu4-detail') }}</h2>
        <el-row>
          <el-col :sm="24" :md=24 :lg=24 >
            <el-card class="box-card">
              <div class="sub_table el-table-m">
                <dl class="display_detail">
                  <dt>{{ $t('columnValidatorId') }}</dt>
                  <dd>{{ validatorData.moniker }}</dd>
                  <dt>{{ $t('columnValidatorAddress') }}</dt>
                  <dd>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_detail_txt">{{validatorData.operator_address}}</span>
                      <span class="ico_copy"
                        @click="$func.copy_to_clipboard(validatorData.operator_address);
                        $message({message: $t('msgCopy'), type: 'success' });">
                        copy
                      </span>
                    </div>
                  </dd>
                  <dt>{{ $t('columnWalletAddress') }}</dt>
                  <dd>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_detail_txt">{{validatorData.wallet_address}}</span>
                      <span class="ico_copy"
                        @click="$func.copy_to_clipboard(validatorData.wallet_address);
                        $message({message: $t('msgCopy'), type: 'success' });">
                        copy
                      </span>
                    </div>
                  </dd>
                  <dt>{{ $t('columnWebsite') }}</dt>
                  <dd>{{ validatorData.website }}</dd>
                  <dt>{{ $t('columnCommission') }}</dt>
                  <dd>{{ validatorData.commission }}</dd>
                  <dt>{{ $t('columnCommissionUpdated') }}</dt>
                  <dd>{{ validatorData.update_time }} UTC</dd>
                  <dt>{{ $t('columnVotingPower') }}</dt>
                  <dd>{{validatorData.voting_power}}</dd>
                  <dt>{{ $t('txDelegated') }}</dt>
                  <dd> <span v-html="$options.filters.currency(validatorData.tokens)"></span>
                    {{validatorData.symbol}}</dd>
                </dl>
              </div>
              <el-descriptions class="margin-top el-table" :column="1"  border>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnValidatorId') }}
                    </template>
                    {{validatorData.moniker}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                    {{ $t('columnValidatorAddress') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{validatorData.operator_address}}</span>
                      <span class="ico_copy"
                            @click="$func.copy_to_clipboard(validatorData.operator_address);
                                      $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                    {{ $t('columnWalletAddress') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{validatorData.wallet_address}}</span>
                      <span class="ico_copy"
                            @click="$func.copy_to_clipboard(validatorData.wallet_address);
                                      $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnWebsite') }}
                    </template>
                    {{validatorData.website}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnCommission') }}
                    </template>
                    {{validatorData.commission}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnCommissionUpdated') }}
                    </template>
                    {{validatorData.update_time}} UTC
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnVotingPower') }}
                    </template>
                      {{validatorData.voting_power}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('txDelegated') }}
                    </template>
                    <span v-html="$options.filters.currency(validatorData.tokens)"></span>
                      {{validatorData.symbol}}
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
          </el-col>
          <el-col :sm="24" :md=12 :lg=16 class="pr10">
            <h2 class="sub-title2">{{ $t('txtValidatorBlock') }}</h2>
            <Stable :columnes="blockColumnes" :tableData="blockData"
                    :loading="false"
                    :total_data = "blockDataTotal"
                    @handleCurrentChange="blockCurrentChange">
            </Stable>
          </el-col>
          <el-col :sm="24" :md=12 :lg=8  class="type_02 pl10">
            <h2 class="sub-title2">{{ $t('txtDelegatedUnbonding') }}</h2>
            <Stable :columnes="delegatorColumnes" :tableData="delegatorDeisplayData"
                    :loading="loading"
                    :total_data="delegatorDataTotal"
                    @handleCurrentChange="delegatorCurrentChange">
            </Stable>
          </el-col>
        </el-row>
      </div>
    </div>
</template>
<script>
import Stable from '@/components/Stable.vue';

export default {
  name: 'validatorDetail',
  components: {
    Stable,
  },
  data() {
    return {
      loading: false,
      blockColumnes: [{
        label: 'columnHeight', prop: 'block_nm', width: '80', class: '', router: 'blocksDetail', key: 'block_nm',
      },
      {
        label: 'columnBlockHash', prop: 'block_hash', width: '150', class: '', router: 'blocksDetail', key: 'block_nm',
      },
      {
        label: 'columnTxs', prop: 'num_txs', width: '100', class: '', router: '', key: '',
      },
      {
        label: 'columnTime', prop: 'time_ago', width: '200', class: '', router: '', key: '',
      },
      ],
      delegatorColumnes: [{
        label: 'columnDelegatorAddress', prop: 'delegator_address', width: '', class: 'fl_left', router: 'accountDetail', key: 'delegator_address',
      },
      {
        label: 'columnGURUCoin', prop: 'tokens', width: '', class: '', router: '', key: '',
      },
      ],
      tableData: [],
      validatorData: {},
      validatorId: '',
      blockData: [],
      blockDataTotal: 0,
      delegatorData: [],
      delegatorDataTotal: 0,
      delegatorPage: 1,
    };
  },
  methods: {
    delegatorCurrentChange(val) {
      this.delegatorPage = val;
    },
    blockCurrentChange(val) {
      this.loading = true;
      this.getBlocks(val);
    },
    async getValidator() {
      try {
        const { data } = await this.$axios.get(`/validators/${this.validatorId}`);
        if (data.ret_code === 200) {
          this.validatorData = data.data.validator;
          this.delegatorData = data.data.delegators;
          this.delegatorDataTotal = data.data.delegators.length;
        } else {
          this.$message.error(data.msg);
          this.$router.push({ name: 'validators' });
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getBlocks(page) {
      try {
        const { data } = await this.$axios.get(`/validators/${this.validatorId}/blocks?page_no=${page}&list_size=7i`);
        if (data.ret_code === 200) {
          this.blockData = data.data.blocks;
          this.blockDataTotal = data.data.block_tot;
        } else {
          this.$message.error(`${this.validatorId}: ${this.$t('411codeMessage')}`);
          this.$router.push({ name: 'validators' });
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.validatorId = this.$route.params.me;
    this.getValidator();
  },
  mounted() {
    this.getBlocks(1);
  },
  computed: {
    delegatorDeisplayData() {
      if (!this.delegatorData || this.delegatorData.length === 0) return [];
      return this.delegatorData.slice(10 * this.delegatorPage - 10, 10 * this.delegatorPage);
    },
  },
};
</script>
